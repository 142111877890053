<template>
  <div class="w-block__pre-heading">
    <!-- pre-heading block -->
  </div>
</template>
<script>
export default {
  components: {},
}
</script>
